export const types = {

  // UI
  services: '[UI] set marketplace services',
  loading: '[UI] set loading app',
  user: '[UI] set user authentication',
  cityId: '[UI] set city id',
  searchValue: '[UI] set search value',
  servicesByCategory: '[UI] set services by category selected',
  serviceSelected: '[UI] set current service selected',
  cityConfig: '[UI] set cityConfig',
  openSearch: '[UI] open search results in desktop mode',
  userPosition: '[UI] set current user position',
  openCountries: '[UI] set current open countries',
  openCities: '[UI] set current open cities',
  countrySelected: '[UI] set current country selected',

  countries_list: '[db] set current countries',
  cities_list: '[db] set current cities',

  // RECHARGE
  companies: '[RECHARGE] set recharge companies',
  company: '[RECHARGE] set company selected',
  bus_number: '[RECHARGE] set current bus card number',
  bus_name: '[RECHARGE] set current bus card name',
  card: '[RECHARGE] set card selected',
  amounts: '[RECHARGE] set card amounts',
  cards: '[RECHARGE] set user cards',
  delete_card: '[RECHARGE] set open delete card dialog',
  debit_number: '[RECHARGE] set current debit card number',
  debit_date: '[RECHARGE] set current debit card date',
  debit_card: '[RECHARGE] set debit card data',
  edit_card: '[RECHARGE] set open edit card dialog',
  terminals: '[RECHARGE] set recharge terminals',
  forceRedirect: '[RECHARGE] set force redirect'

}
