import { firebase, ualabeeApiHost } from '../firebase/firebase-config'

export const getAllCities = async (countrySelected) => {
  const reqBody = {
    country_id: countrySelected,
    city_enabled: true
  }

  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${ualabeeApiHost}/cities/getByStatus`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .then((response) => response?.result)
    .catch((err) => console.log(err))
}
