import React from 'react'
import { CircularProgress, Dialog } from '@material-ui/core'
import { Container, Icon, Text } from './styles'
import { useStyles } from './material'

export const Redirect = ({ open }) => {
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.paper }} open={open}>
      <Container>
        <Icon>
          <CircularProgress style={{ marginRight: 16, color: '#197d8f', width: 30, height: 30 }} />
        </Icon>
        <Text>
          Redirigiendo al formulario de pago
        </Text>
      </Container>
    </Dialog>
  )
}
