import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentBusName } from '../../../../actions/recharge'
import { Input } from '../../../../styles'

export const InputName = ({ error }) => {
  const dispatch = useDispatch()

  const card = useSelector(state => state?.recharge?.bus_card)

  const handleChangeName = (value) => {
    if (value?.length <= 30) {
      dispatch(setCurrentBusName(value))
    }
  }

  return (
    <Input
      id='outlined-number'
      focused={error?.name}
      color={error?.name ? '#f44336' : '#197D8F'}
      label='Nombre de tarjeta'
      type='text'
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      placeholder='Ej: mi tarjeta'
      value={card?.name}
      onChange={(e) => handleChangeName(e.target.value)}
      required
      error={error?.name}
      helperText={error?.name && 'Este nombre ya existe'}
    />
  )
}
