import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Details } from '../components/services/details/Details'
import { App } from '../App'
import { Search } from '../components/search/Search'
import { ByCategory } from '../components/services/byCategory/ByCategory'
import ScrollToTop from '../uiComponents/ScrollToTop/idex'
import { CardInformation } from '../components/mobile/movypay/CardInformation'
import { Amount } from '../components/mobile/movypay/Amount'
import { Cards } from '../components/mobile/movypay/Cards'
import { Download } from '../components/mobile/movypay/Download'
import { Terminals } from '../components/mobile/movypay/Terminals'
import { startLogin } from '../actions/auth'
import { useDispatch } from 'react-redux'
import ActiveCoupon from '../components/ActiveCoupon'

export const AppRouter = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startLogin())
  }, [])

  return (
    <Router>

      <div>

        <Switch>

          <Route
            exact
            path='/'
            component={App}
          />

          <Route
            exact
            path='/search'
            component={Search}
          />

          <Route
            exact
            path='/card-recharge/card-data'
            component={CardInformation}
          />

          <Route
            exact
            path='/card-recharge/amount-selection'
            component={Amount}
          />

          <Route
            exact
            path='/card-recharge/user-cards'
            component={Cards}
          />

          <Route
            exact
            path='/card-recharge/payment'
            component={Download}
          />

          <Route
            exact
            path='/card-recharge/terminals'
            component={Terminals}
          />

          <Route
            exact
            path='/:category'
            component={ByCategory}
          />

          <Route
            exact
            path='/:category/:service'
            component={Details}
          />

          <Route
            exact
            path='/:cityId/services/ticket/:transactionId'
            component={ActiveCoupon}
          />

          <Route
            exact
            path='/:cityId/product/:transactionId'
            component={ActiveCoupon}
          />

          <Redirect to='/' />

        </Switch>

      </div>

      <ScrollToTop />

    </Router>
  )
}
