import styled from 'styled-components/macro'

export const ComponentContainer = styled.div`  
    display: flex;
    align-items: center;   
    justify-content: center;
    margin: 0 0 35px 0; 
`

export const Container = styled.div`  
    display: flex; 
    flex-direction: column;
    width: 60%;   
`

export const Title = styled.h2`  
    font-family: Visby;
    font-size: 24px;
    margin: 0 0 27px 6px;
`
