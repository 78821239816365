import styled from 'styled-components/macro'

export const Container = styled.div`  
    display: flex;
    align-items: center;
    width: 100px;
`

export const Text = styled.p`  
    margin: 0;
    margin-right: 10px;
    font-size: 8px;
`

export const Logo = styled.img`
    width: 15px;   
`
