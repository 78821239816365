import React from 'react'
import { useSelector } from 'react-redux'
import { TypesContainer } from '../../../../../services/types/Types/style'
import { ComponentContainer, Container, Title } from './styles'
import { Type } from '../../../../../../uiComponents/services/types/Type'

export const Types = () => {
  const serviceTypes = useSelector(state => state?.services?.services?.types)

  return (
    <ComponentContainer>
      <Container>
        <Title>Categorías</Title>
        <TypesContainer>
          {serviceTypes?.map((type) => {
            const categoryName = type?.name?.split(' ').join('-').replace('&', 'y').toLowerCase()
            return (
              <Type id={categoryName} key={type?.id} type={type} />
            )
          })}
        </TypesContainer>
      </Container>
    </ComponentContainer>
  )
}
