import { types } from '../types/types'

const initialState = {
  mode: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  current: {
    services: {
      byCategory: undefined
    }
  },
  components: {
    searcher: {
      enabled: false
    },
    countries: false,
    cities: false
  },
  countrySelected: undefined
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.servicesByCategory:
      return {
        ...state,
        current: {
          ...state.current,
          services: {
            ...state.current.services,
            category: action.payload
          }
        }
      }
    case types.serviceSelected:
      return {
        ...state,
        current: {
          ...state.current,
          services: {
            ...state.current.services,
            service: action.payload
          }
        }
      }
    case types.cityConfig:
      return {
        ...state,
        cityConfig: action.payload
      }

    case types.openSearch:
      return {
        ...state,
        components: {
          ...state?.components,
          searcher: {
            ...state?.components?.searcher,
            enabled: action.payload
          }
        }
      }
    case types.userPosition:
      return {
        ...state,
        userPosition: action.payload

      }
    case types.openCountries:
      return {
        ...state,
        components: {
          ...state?.components,
          countries: action.payload
        }
      }
    case types.openCities:
      return {
        ...state,
        components: {
          ...state?.components,
          cities: action.payload
        }
      }
    case types.countrySelected:
      return {
        ...state,
        countrySelected: action.payload
      }
    default:
      return state
  }
}
