import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'

export const ComponentContainer = styled(Button)`  
    padding: ${props => props?.mode ? '0' : '6px 8px'} !important;
    margin: ${props => props?.mode ? '0 0 10px 0' : '0 8px'} !important; 
`

export const Image = styled.div`    
    width: ${props => props?.mode ? '60px' : '40px'};
    height: ${props => props?.mode ? '60px' : '40px'};
    min-width: ${props => props?.mode ? '60px' : '40px'};
    min-height: ${props => props?.mode ? '60px' : '40px'};
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    border-radius: ${props => props?.mode ? '8px' : '8px'};
    margin-right: ${props => props?.mode ? '16px' : '16px'};
`

export const Information = styled.div`
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-direction: column;
`

export const Name = styled.p`     
    margin: 0; 
    font-size: ${props => props?.mode ? '18px' : '16px'};
    font-weight: ${props => props?.mode ? '600' : '600'};
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const Description = styled.p` 
    margin: 0;
    font-size: 14px; 
    color: ${props => props?.mode ? 'inherit' : '#909397'};
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props?.mode ? '1' : '2'} !important;
    -webkit-box-orient: vertical;
    text-align: -webkit-left;
`
