import React from 'react'
import PropTypes from 'prop-types'
import { Image, ImageContainer, Name, TypeContainer } from './style'
import { useStyles } from './materialStyles'
import { useServices } from '../../../../hooks/useServices'

export const Type = ({ type }) => {
  const { icon, name, color } = type

  const classes = useStyles()

  const {
    handleClickServiceType
  } = useServices()

  return (
    <TypeContainer
      className={classes.serviceType}
      onClick={() => handleClickServiceType(type)}
    >
      <ImageContainer color={color}>
        <Image alt='' src={icon} />
      </ImageContainer>
      <Name>{name}</Name>
    </TypeContainer>
  )
}

Type.propTypes = {
  type: PropTypes.object.isRequired
}
