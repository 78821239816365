import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'

export const NewContainer = styled(Button)` 
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;  
    max-width:  ${props => props?.mode ? 'auto' : '104px'}; 
`

export const Image = styled.div` 
    width: ${props => props?.mode ? '48px' : '88px'};
    height: ${props => props?.mode ? '48px' : '88px'};
    min-width: ${props => props?.mode ? '48px' : '88px'};
    min-height: ${props => props?.mode ? '48px' : '88px'};
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    border-radius: 8px;
`

export const Text = styled.p` 
    margin: 6px;
    font-size: 14px;
    text-align: center;
    max-height: 56px;
    height: ${props => props?.mode ? 'auto' : '48px'};
    max-width:  ${props => props?.mode ? 'auto' : '100%'};
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 

`
