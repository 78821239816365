import React from 'react'
import { useSelector } from 'react-redux'
import { SkeletonMobile } from '../../SkeletonMobile'
import { Searcher } from '../../../search/Searcher'
import { Types } from '../../../services/types/Types'
import { FeaturedServices } from '../../../services/featured/FeaturedServices'
import { News } from '../../../services/news/News'
import { NotFeatured } from '../../../services/notFeatured/NotFeatured'
import { Countries } from '../../../../uiComponents/Countries'
import { Cities } from '../../../../uiComponents/Cities'

export const Marketplace = () => {
  const { loading } = useSelector(state => state?.services)

  return (
    <div>
      {loading
        ? (
          <>
            <SkeletonMobile />
            <Countries />
            <Cities />
          </>
          )
        : <>
          <div style={{ padding: '0 16px' }}>
            <Searcher />
            <Types />
            <FeaturedServices />
            <News />
          </div>
          <NotFeatured />
        </>}
    </div>
  )
}
