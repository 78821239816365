import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../../uiComponents/movypay/Navbar'
import {
  Amounts,
  Card,
  CompanyName,
  Container,
  Content,
  ErrorIcon,
  ErrorMessage,
  Information,
  LoadingContainer,
  Name,
  Number,
  Price,
  Title
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCard, setUserCards as setCurrentUserCards } from '../../../../actions/recharge'
import { useStyles } from './material'
import { Primary as Continue } from '../../../../uiComponents/movypay/buttons/Primary'
import { Secondary as SelectAnotherCard } from '../../../../uiComponents/movypay/buttons/Secondary'
import { useHistory } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'
import { getUrlForm } from '../../../../db/movypay/getUrlForm'
import { Button, CircularProgress, Modal } from '@material-ui/core'
import { deleteUserRechargeCard } from '../../../../db/movypay/deleteUserRechargeCard'
import { setUserCards } from '../../../../db/movypay/localData'
import useQuery from '../../../../hooks/useQuery'
import { Redirect } from '../../../../uiComponents/movypay/Redirect'
import { getCompanyAmounts } from '../../../../db/movypay/getCompanyAmounts'

export const Amount = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()

  const classes = useStyles()

  const company = useSelector(state => state?.recharge?.company)
  const card = useSelector(state => state?.recharge?.bus_card)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const uid = useSelector(state => state?.auth?.uid)
  const cards = useSelector(state => state?.recharge?.user_cards)

  const [open, setOpen] = useState({ enabled: false })
  const [redirecting, setRedirecting] = useState(false)
  const [amounts, setAmounts] = useState([])

  useEffect(() => {
    if (company) {
      getCompanyAmounts(company?.payment_ref_id)
        .then(response => {
          setAmounts(response?.Montos)
        })
        .catch((error) => {
          console.log('Error', error)
        })
    }
  }, [company])

  const handleClick = async () => {
    setRedirecting(true)

    logEvent('recharge_card_amount_confirm', {
      card_name: card?.name, // String
      card_number: card?.card_ref_id, // String
      card_id: card?.id, // Int
      card_company_name: card?.recharge_services?.name, // String
      amount_selected: card?.amount_selected?.toString(), // String
      user_id: uid, // String
      city_id: cityConfig?.city_id // Int
    })

    const params = {
      recharge_service_id: company?.id,
      amount: parseInt(card?.amount_selected),
      session_id: uid,
      user_recharge_card_id: card?.id,
      city_id: cityConfig?.city_id
    }

    try {
      const response = await getUrlForm(params)
      if (response?.UrlFormulario && response.Error === null) {
        window.open(response?.UrlFormulario, '_self')
        setRedirecting(false)
      } else if (response?.UrlFormulario === null) {
        setOpen({ enabled: true, message: response?.Error?.ErrorDescription })
        setRedirecting(false)
      }
    } catch (e) {
      setRedirecting(false)
      console.log(e)
      alert('Ha ocurrido un error.')
    }
  }

  const handlePrice = (amount) => {
    logEvent('recharge_card_amount_select', {
      card_name: card?.name, // String
      card_number: card?.card_ref_id, // String
      card_id: card?.id, // Int
      card_company_name: card?.recharge_services?.name, // String
      amount_selected: amount?.toString(), // String
      user_id: uid, // String
      city_id: cityConfig?.city_id // Int
    })

    dispatch(setCurrentCard({ ...card, amount_selected: amount }))
  }

  const handleSelectAnother = () => {
    dispatch(setCurrentCard({ name: '', number: '' }))

    if (cards?.length > 0) {
      if (cards?.length === 1) {
        history.push(`/card-recharge/card-data?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
      } else {
        history.push(`/card-recharge/user-cards?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
      }
    } else {
      alert('No existen tarjetas cargadas')
    }
  }

  const handleCloseError = async () => {
    await deleteUserRechargeCard({
      id: card?.id
    })

    logEvent('recharge_card_remove', {
      card_id: card?.id, // Int
      card_name: card?.name, // String
      card_company_name: card?.recharge_services?.name, // String
      card_number: card?.card_ref_id, // String
      user_id: uid, // String
      city_id: cityConfig?.city_id // Int
    })

    const current = cards?.filter(cardItem => cardItem?.name !== card?.name)

    setUserCards(current)

    dispatch(setCurrentCard({ name: '', number: '' }))
    dispatch(setCurrentUserCards(current))

    setOpen({ enabled: false })

    if (current?.length > 1) {
      history.push(`/card-recharge/user-cards?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
    } else {
      history.push(`/card-recharge/card-data?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
    }
  }

  return (
    <Container>
      {company && amounts?.length >= 1
        ? (
          <>
            <Navbar label='Seleccionar importe' />
            <Card>
              <Information>
                <CompanyName>{company?.name}</CompanyName>
                <Name>{card?.name}</Name>
                <Number>{card?.card_ref_id}</Number>
              </Information>

              {!company && <Title>¿Cuánto querés pagar?</Title>}
              <Amounts>
                {amounts.map((amount) => {
                  return (
                    <Price
                      key={amount}
                      className={amount === card?.amount_selected ? classes.selected : classes.price}
                      onClick={() => handlePrice(amount)}
                    >
                      $ {amount}
                    </Price>
                  )
                })}
              </Amounts>
            </Card>
            <Continue
              label='Continuar' onClickFunction={handleClick} disabled={!card?.amount_selected}
              bottom={62}
            />
            <SelectAnotherCard
              label={`Utilizar otra ${company?.name}`}
              onClickFunction={handleSelectAnother}
              bottom={16}
            />
            <Redirect open={redirecting} />
          </>
          )
        : (
          <LoadingContainer>
            <CircularProgress color='inherit' />
          </LoadingContainer>
          )}
      <Modal
        className={classes.modal}
        aria-labelledby='invalid-bus-card'
        open={open?.enabled}
        onClose={handleCloseError}
      >
        <Content>
          <ErrorIcon className={classes.error} />
          <ErrorMessage>{open.message}</ErrorMessage>
          <Button
            onClick={handleCloseError}
            className={classes.accept}
            variant='outlined'
          >
            Aceptar
          </Button>
        </Content>
      </Modal>
    </Container>
  )
}
