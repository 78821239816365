import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'

export const TypeContainer = styled(Button)` 
`

export const ImageContainer = styled.div`
    margin-top: 10px;
    background-color: ${props => props?.color && props?.color}24;  
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 64px;
    height: 64px; 
    border-radius: 14px;
`

export const Image = styled.img`
    width: 64px;
    height: 64px; 
`

export const Name = styled.p` 
    margin: 4px 0 10px 0;
    font-size: 14px;
    color: #909397;
    text-align: center;
    line-height: 14px;
`
