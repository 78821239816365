import React from 'react'
import { NotFeaturedContainer } from './styles'
import { useServices } from '../../../../hooks/useServices'
import { ServiceGroup } from '../ServiceGroup'

export const NotFeatured = () => {
  const {
    groupedServices
  } = useServices()

  return (
    <NotFeaturedContainer>
      {groupedServices?.map((group) => {
        return (
          <ServiceGroup
            key={group?.groupId}
            group={group}
          />
        )
      })}
    </NotFeaturedContainer>
  )
}
