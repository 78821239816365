import React from 'react'
import { NewContainer, Image, Text, Container } from './style'
import PropTypes from 'prop-types'
import { useServices } from '../../../../hooks/useServices'
import { useStyles } from './materialStyles'
import { useSelector } from 'react-redux'

export const New = ({ icon, name, service, from }) => {
  const classes = useStyles()
  const { handleClickService } = useServices()

  const mode = useSelector(state => state?.ui?.mode)

  return (
    <NewContainer
      className={mode ? classes.serviceMobile : classes.serviceDesktop}
      onClick={() => handleClickService(service, from)}
    >
      <Container>
        <Image mode={mode} src={icon} />
        <Text>{name}</Text>
      </Container>

    </NewContainer>
  )
}

New.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired
}
