import { firebase, ualabeeApiHost } from '../firebase/firebase-config'
import { getLocalVariantId } from './getLocalVariantId'

export const getServices = async (cityId) => {
  const localCityId = localStorage.getItem('city_id')

  const reqBody = {
    city_id: cityId?.toString() || localCityId?.toString()
  }

  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': getLocalVariantId()
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${ualabeeApiHost}/marketplace/getServices`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .then(response => response?.result)
    .catch((err) => console.log(err))
}
