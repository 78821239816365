import { firebase, ualabeeApiHost } from '../../firebase/firebase-config'

export const getUserPoints = async ({ cityId, agencyId }) => {
  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  }

  return fetch(`${ualabeeApiHost}/rewards/tickets/getUserPoints?cityId=${cityId}&agencyId=${agencyId}`, reqConfig)
}
