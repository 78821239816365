import React from 'react'
import { Type } from '../Type'
import { ServiceTypes, TypesContainer } from './style'
import { useSelector } from 'react-redux'

export const Types = () => {
  const serviceTypes = useSelector(state => state?.services?.services?.types)
  const mode = useSelector(state => state?.ui?.mode)

  return (
    <ServiceTypes>
      <TypesContainer mode={mode}>
        {serviceTypes?.map((type) => {
          return (
            <Type
              key={type.id}
              type={type}
            />
          )
        })}
      </TypesContainer>
    </ServiceTypes>
  )
}
