import React from 'react'
import { Button, Icon } from './styles'

export const UserAvatar = () => {
  return (
    <div style={{ width: 36, height: 36 }}>
      <Button style={{ width: 36, height: 36, minWidth: 36, display: 'none' }} color='secondary' aria-label='edit'>
        <Icon />
      </Button>
    </div>
  )
}
