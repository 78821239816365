import styled from 'styled-components/macro'
import { IconButton, TextField } from '@material-ui/core'
import { ArrowBack, Share } from '@material-ui/icons'

export const BackButton = styled(IconButton)``

export const BackIcon = styled(ArrowBack)``

export const ShareButton = styled(IconButton)``

export const ShareIcon = styled(Share)``

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`

export const SwipeableIcon = styled.div`
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 4px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: table; 
`
export const Input = styled(TextField)`
    width: 100%;
    margin-top: 12px !important;
    & label.Mui-focused {
        color: ${props => props?.color};
    }
    & .MuiOutlinedInput-root {  
        border-radius: 10px !important;
        &.Mui-focused fieldset {
            border-color: ${props => props?.color};
        }
    }
`
