import styled from 'styled-components/macro'

export const ComponentContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center; 
`

export const Container = styled.div` 
    width: 60%;   
`

export const Title = styled.h2`   
`
