import React from 'react'
import { Confirm } from './styles'
import { useStyles } from './material'
import PropTypes from 'prop-types'

export const Secondary = ({ onClickFunction, label, bottom, styles }) => {
  const classes = useStyles()

  return (
    <Confirm
      style={{ bottom, ...styles }}
      className={classes.secondary}
      onClick={onClickFunction}
      type='submit'
    >
      {label}
    </Confirm>
  )
}

Secondary.propTypes = {
  onClickFunction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  bottom: PropTypes.number.isRequired
}
