import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, useMapEvent } from 'react-leaflet'
import { useSelector } from 'react-redux'
import L from 'leaflet'
import markerIcon from '../../../../img/movypay/terminal-marker.svg'
import terminalImg from '../../../../img/movypay/terminal.jpg'
import { Drawer, IconButton } from '@material-ui/core'
import { LocationSearching } from '@material-ui/icons'
import {
  Description,
  Direction,
  DirectionContainer,
  DirectionIcon,
  Location, ScheduleIcon,
  TerminalImage,
  TerminalInfo
} from './styles'
import { useStyles } from './material'
import { useHistory } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'

export const Terminals = () => {
  const classes = useStyles()
  const history = useHistory()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const terminals = useSelector(state => state?.recharge?.terminals)
  const uid = useSelector(state => state?.auth?.uid)
  const company = useSelector(state => state?.recharge?.company)

  const [zoom, setZoom] = useState(cityConfig?.map?.map_zoom_level)
  const [center, setCenter] = useState([cityConfig?.map?.map_center_lat, cityConfig?.map?.map_center_lng])
  const [open, setOpen] = useState({ enabled: false })
  const [bounds, setBounds] = useState()
  const [position, setPosition] = useState()
  const [map, setMap] = useState(null)

  useEffect(() => {
    if (!cityConfig) {
      history.push('/')
    }

    navigator.geolocation.getCurrentPosition((pos) => {
      const crd = pos.coords
      setPosition([crd?.latitude, crd?.longitude])
    })

    const corner1 = L.latLng(
      cityConfig?.map?.map_southwest_lat,
      cityConfig?.map?.map_southwest_lng
    )
    const corner2 = L.latLng(
      cityConfig?.map?.map_northeast_lat,
      cityConfig?.map?.map_northeast_lng
    )

    setBounds(L.latLngBounds(corner1, corner2))
  }, [])

  function MapEvents() {
    useMapEvent('moveend', (e) => {
      setZoom(e.target._zoom)
    })
    return null
  }

  const getTerminalMarkerIcon = () => {
    let size

    if (zoom === 17 || zoom === 18) {
      size = [20, 20]
    } else if (zoom > 18) {
      size = [25, 25]
    } else {
      size = [10, 10]
    }

    return (L.divIcon({
      iconSize: size,
      html: `${
                zoom <= 16
                    ? '<span style=\'display: block; width:10px; height:10px; background-color: #0A80B5; border-radius:100%; border:1px solid white;\'></span>'
                    : `<img style="width:100%;" src=${markerIcon} alt="refill"/>`
            }`,
      className: 'marker'
    }))
  }

  const handleClickTerminal = (item) => {
    setOpen({ enabled: true, item })

    map.flyTo([item?.Latitud, item?.Longitud], 16)

    logEvent('recharge_card_terminal', {
      lat: Number(item?.Latitud), // Double
      lng: Number(item?.Longitud), // Double
      city_id: cityConfig?.city_id, // Int
      user_id: uid, // String
      card_company_name: company?.name, // String
      terminal_location: item.Ubicacion, // String
      terminal_address: item.Direccion, // String
      terminal_schedule: item.Horario // String
    })
  }

  const handleLocation = () => {
    setCenter(position)
    map.flyTo(position, 17)
  }

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <MapContainer
        zoomControl={false}
        center={center}
        zoom={zoom}
        whenCreated={setMap}
      >
        <MapEvents />

        {terminals?.map((item, index) => {
          const position = [Number(item?.Latitud), Number(item?.Longitud)]

          return (
            <Marker
              key={index}
              eventHandlers={{
                click: () => {
                  handleClickTerminal(item)
                }
              }}
              position={position}
              icon={getTerminalMarkerIcon(item)}
            />
          )
        })}

        <TileLayer
          url='https://tiles.ualabee.com/styles/klokantech-basic/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        <Drawer
          classes={{ paper: classes.paper }}
          anchor='bottom'
          open={open?.enabled}
          onClose={() => setOpen({ enabled: false })}
        >
          <TerminalImage src={terminalImg} />
          <TerminalInfo>
            <Description>Terminal automática</Description>
            <Location>{open?.item?.Ubicacion}</Location>
            <DirectionContainer>
              <DirectionIcon />
              <Direction>{open?.item?.Direccion}</Direction>
            </DirectionContainer>
            <DirectionContainer>
              <ScheduleIcon />
              <Direction>{open?.item?.Horario}</Direction>
            </DirectionContainer>
          </TerminalInfo>
        </Drawer>

      </MapContainer>

      {position && bounds?.contains(L.latLng(position[0], position[1])) &&
        <IconButton
          style={{
            position: 'absolute',
            zIndex: 450,
            bottom: 96,
            right: 16,
            background: 'white'
          }}
          onClick={handleLocation}
        >
          <LocationSearching />
        </IconButton>}

    </div>

  )
}
