import React from 'react'

import logo from '../../../../../img/ualabeelogo.svg'
import { Searcher } from '../../../../search/Searcher'
import { ComponentContainer, Container, Logo } from './styles'
import { UserAvatar } from '../UserAvatar'

export const Header = () => {
  return (
    <ComponentContainer>
      <Container>
        <Logo src={logo} />
        <Searcher />
        <UserAvatar />
      </Container>
    </ComponentContainer>
  )
}
