import React from 'react'
import PropTypes from 'prop-types'
import { Image, ImageContainer, Name, TypeContainer } from './style'
import { useStyles } from './materialStyles'
import { useServices } from '../../../../hooks/useServices'
import { useSelector } from 'react-redux'

export const Type = ({ id, type }) => {
  const { icon, name, color } = type

  const classes = useStyles()
  const { handleClickServiceType } = useServices()
  const mode = useSelector(state => state?.ui?.mode)

  return (
    <TypeContainer
      id={'category-' + id}
      className={mode ? classes.serviceTypeMobile : classes.serviceTypeDesktop}
      onClick={() => handleClickServiceType(type)}
      href={`#${id}`}
    >
      <ImageContainer
        color={color}
        mode={mode}
      >
        <Image alt='' src={icon} />
      </ImageContainer>
      <Name mode={mode}>
        {name}
      </Name>
    </TypeContainer>
  )
}

Type.propTypes = {
  type: PropTypes.object.isRequired
}
