import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  serviceMobile: {
    width: '30%',
    minWidth: '30%',
    '& .MuiButton-label': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxShadow: '0 2px 4px rgba(34, 40, 49, 0.1)',
      borderRadius: '8px',
      height: '122px',
      textTransform: 'none',
      fontFamily: 'Visby'
    },
    marginRight: '15px',
    padding: 0,
    '&:last-child': {
      marginRight: 0
    }
  },
  serviceDesktop: {
    borderRadius: '8px',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxShadow: '0 2px 4px rgba(34, 40, 49, 0.1)',
      borderRadius: '8px',
      textTransform: 'none',
      fontFamily: 'Visby',
      padding: '46px 43px'
    },
    marginRight: '15px',
    padding: 0,
    '&:last-child': {
      marginRight: 0
    }
  }
}))
