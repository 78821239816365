import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  serviceType: {
    maxWidth: '33.3%',
    minWidth: '33.3%',
    '& .MuiButton-label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexDirection: 'column',
      textTransform: 'none',
      fontFamily: 'Visby'
    },
    borderRadius: 8,
    padding: 0
  }
}))
