import styled from 'styled-components/macro'
import { Button, TextField } from '@material-ui/core'

export const Container = styled.div` 
    width: calc(100vw - 36px); 
`

export const Title = styled.p`  
    margin: 0;
    font-size: 16px;
    padding: 16px 0 0 16px; 
`

export const Input = styled(TextField)`
    width: 100%;
    margin-top: 12px !important;
    & label.Mui-focused {
        color: ${props => props?.color};
    }
    & .MuiOutlinedInput-root {  
        border-radius: 10px !important;
        &.Mui-focused fieldset {
            border-color: ${props => props?.color};
        }
    }
`

export const InputContainer = styled.div` 
    padding: 16px;
`

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 6px 16px;
    width: 190px;
`

export const Cancel = styled(Button)` 
`

export const Rename = styled(Button)` 
`
