import styled from 'styled-components/macro'
import { Fab } from '@material-ui/core'
import { Person } from '@material-ui/icons'

export const ComponentContainer = styled.div`
    box-shadow: 0 1px 1px rgba(232, 237, 238, 0.5);
`

export const Button = styled(Fab)`     
`

export const Icon = styled(Person)`    
`
