import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  modal: {
    zIndex: '402 !important'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '16px 16px 32px 16px',
    backgroundColor: '#ffffff',
    borderRadius: '10px 10px 0 0',
    boxSizing: 'border-box',
    maxHeight: '80vh',
    outline: 'none !important'
  },
  country: {
    width: '100%',
    textTransform: 'none',
    fontFamily: 'Visby',
    fontSize: 18,
    color: 'rgb(0, 0, 0)'
  },
  countryText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 15
  },
  search: {
    position: 'absolute',
    top: 11.5,
    right: 10,
    color: 'rgb(166, 169, 172)'
  },
  searcher: {
    width: '100% !important',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: '16px 40px 16px 20px;',
    boxSizing: 'border-box',
    fontFamily: 'Visby',
    color: '#000000',
    fontSize: '14px',
    marginBottom: '8px',
    height: '50px',
    position: ' relative'
  }
}))
