function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in km
}

export const pickTextColorBasedOnBgColor = (bgColor, lightColor, darkColor) => {
  const color = (bgColor?.charAt(0) === '#') ? bgColor?.substring(1, 7) : bgColor
  const r = parseInt(color?.substring(0, 2), 16) // hexToR
  const g = parseInt(color?.substring(2, 4), 16) // hexToG
  const b = parseInt(color?.substring(4, 6), 16) // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
    ? darkColor
    : lightColor
}
