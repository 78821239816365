import styled from 'styled-components/macro'

export const ServiceByCategoryContainer = styled.div`  
    position: relative;
`

export const CategoryHeader = styled.div` 
    width: 100%;  
    position: relative; 
    height: 80px;
   
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  
`

export const CategoryContent = styled.div`
    border-top-right-radius: 14px; 
    border-top-left-radius: 14px; 
    position: absolute;
    top: calc(80px - 14px);
    height: 100vh;
    width: 100vw;
    background-color: white;
`

export const ContentContainer = styled.div` 
    padding: 20px 0 0 0;
`

export const Opacity = styled.div`  
    position: absolute;
    top: 0; 
    background-color: #2228318f;   
    width: 100%;  
    height: 100%; 
`

export const Group = styled.div`   
    display: flex;
    align-items: center;
    justify-content: flex-start;   
    margin-bottom: ${props => props?.isNew ? '18px' : '6px'};
    margin-top: ${props => props?.mode ? '15px' : '0'}; 
    
`

export const Icon = styled.div`  
    width: 35px;
    height: 35px;
    background-image: url(${props => props?.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-right: 12px;
`

export const Name = styled.p`   
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 0 48px;
    color: white;
    z-index: 1;
`

export const HeaderContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 12px;
`

export const ServicesGroupedContainer = styled.div`   
    padding: 0 16px;  
`

export const ServicesContainer = styled.div`   
    margin-top: ${props => props?.fistItemIsNew ? '10px' : '0'}; 
`

export const FeaturedContainer = styled.div`    
    padding ${props => props?.featured ? '0 0 0 16px' : '0 16px'}  
`

export const GroupName = styled.p`   
    font-size: 16px; 
    margin: 0;
`
