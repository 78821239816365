import { getServices } from '../db/getServices'
import { getServiceTypes } from '../db/getServiceTypes'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setCurrentService, setCurrentServicesByCategory } from '../actions/ui'
import { setLoading, setSearchValue } from '../actions/services'
import { logEvent } from '../firebase/firebase-config'
import { getRechargeServices } from '../db/movypay/getRechargeServices'
import { setCompanies, setCurrentCard, setCurrentCompany, setUserCards } from '../actions/recharge'
import { getUserRechargeCards } from '../db/movypay/getUserRechargeCards'
import useQuery from './useQuery'

export const useServices = () => {
  const history = useHistory()
  const query = useQuery()
  const dispatch = useDispatch()

  const servicesNotFeatured = useSelector(state => state?.services?.services?.services)
  const featured = useSelector(state => state?.services?.services?.featured)
  const news = useSelector(state => state?.services?.services?.news)
  const user = useSelector(state => state?.auth?.user)
  const mode = useSelector(state => state?.ui?.mode)
  const uid = useSelector(state => state?.auth?.uid)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const services = useSelector(state => state?.services?.services)

  const [groupedServices, setGroupedServices] = useState()

  useEffect(() => {
    if (servicesNotFeatured && !groupedServices) {
      const currentGroupedServices = groupServicesByCategory(servicesNotFeatured)
      setGroupedServices(currentGroupedServices)
    }
  }, [servicesNotFeatured, groupedServices])

  const getAll = async (cityId) => {
    if (!services) {
      const services = await getServices(cityId)
      const serviceTypes = await getServiceTypes()

      return {
        news: services?.new_services,
        featured: services?.featured_services,
        services: services?.services,
        types: serviceTypes
      }
    }
  }

  const handleShowMore = (group) => {
    const { name, color, icon, services, cityId, groupId, image } = group

    logEvent('marketplace_category', {
      user_id: uid,
      city_id: cityId,
      category_id: groupId,
      category_name: name
    })

    //  Featured services that match the id of the service types in the selected category are searched for.
    const featuredServices = featured?.filter((service) => {
      return service.type_id === services[0].type_id
    })

    // Data of the selected category is set in redux
    dispatch(setCurrentServicesByCategory({
      name,
      icon,
      color,
      services,
      featured: featuredServices,
      image
    }))

    const categoryName = name?.split(' ').join('-').replace('&', 'y').toLowerCase()
    history.push(`/${groupId}-${categoryName}?city_id=${cityId}`)
  }

  /**
     * handleClickService()
     * @param service
     * @param from
     * @returns {Promise<void>}
     */

  const handleClickService = async (service, from) => {
    const token = await user?.getIdToken()

    dispatch(setCurrentCard({ name: '', number: '' }))

    const isFeatured = featured?.filter(featured => featured?.id === service.id)[0]?.id
    const isNew = news?.filter(newService => newService?.id === service?.id)[0]?.id
    const serviceName = service?.name?.split(' ').join('-').replace('&', 'y').toLowerCase()
    const categoryName = service?.type?.name?.split(' ').join('-').replace('&', 'y').toLowerCase()

    if (service?.service_action_id) {
      if (service?.service_action_id === 5) { // Open Movypay proyect
        logEvent('marketplace_service_cta', {
          user_id: uid,
          city_id: service?.city_id,
          group_id: service?.group_id,
          group_name: service?.group?.name,
          featured: !!isFeatured,
          recent_release: !!isNew,
          from,
          discount_percentage_value: service?.discount_percentage_value,
          price: service?.price,
          link: service?.link,
          amount_fees: service?.amount_fees,
          interest_fees_value: service?.interest_fees_value,
          service_id: service?.id
        })

        const rechargeServiceId = parseInt(service?.link.split('=')[1])

        const userCards = await getUserRechargeCards(rechargeServiceId)
        dispatch(setUserCards(userCards))

        const companies = await getRechargeServices(token)
        const currentCompany = companies?.find(company => company.id === rechargeServiceId)

        dispatch(setCurrentCompany(currentCompany))
        dispatch(setCompanies(companies))

        if (userCards?.length > 0) {
          if (userCards?.length === 1) {
            dispatch(setCurrentCard(userCards[0]))
            history.push(`/card-recharge/amount-selection?recharge_service_id=${rechargeServiceId}&card_id=${userCards[0]?.id}&custom_token=${query.get('custom_token') || null}`)
          } else {
            history.push(`/card-recharge/user-cards?recharge_service_id=${rechargeServiceId}&custom_token=${query.get('custom_token') || null}`)
          }
        } else {
          history.push(`/card-recharge/card-data?recharge_service_id=${rechargeServiceId}&custom_token=${query.get('custom_token') || null}`)
        }
      } else {
        // add the rest of the params at the end of the url
        const params = new URLSearchParams(window.location.search)
        const paramsString = params.toString()
        // TODO: remove the duplicated params

        const finalUrl = `${service?.type?.id}-${categoryName}/${service?.id}-${serviceName}?city_id=${service?.city_id}&${paramsString}`
        history.push(finalUrl)

        dispatch(setSearchValue(undefined))
        dispatch(setCurrentService(service))

        logEvent('marketplace_service', {
          user_id: uid,
          city_id: service?.city_id,
          group_id: service?.group_id,
          group_name: service?.group?.name,
          featured: !!isFeatured,
          recent_release: !!isNew,
          from,
          service_id: service?.id
        })
      }
    } else {
      logEvent('marketplace_service_cta', {
        user_id: uid,
        city_id: service?.city_id,
        group_id: service?.group_id,
        group_name: service?.group?.name,
        featured: !!isFeatured,
        recent_release: !!isNew,
        from,
        discount_percentage_value: service?.discount_percentage_value,
        price: service?.price,
        link: service?.link,
        amount_fees: service?.amount_fees,
        interest_fees_value: service?.interest_fees_value,
        service_id: service?.id
      })
      window.open(service?.link, mode ? '_self' : '_blank')
    }
  }

  /**
     * groupServicesByCategory()
     * @param services
     * @returns {unknown[]} - services grouped and ordered by order
     */
  const groupServicesByCategory = (services) => {
    const servicesByType = _.groupBy(services, 'type_id')

    const servicesGrouped = Object?.keys(servicesByType)?.map((key) => {
      return {
        groupId: servicesByType[key][0]?.type?.id,
        cityId: servicesByType[key][0].city_id,
        order: servicesByType[key][0]?.type?.order,
        icon: servicesByType[key][0]?.type?.icon,
        color: servicesByType[key][0]?.type?.color,
        name: servicesByType[key][0]?.type?.name,
        services: servicesByType[key],
        image: servicesByType[key][0]?.type?.image
      }
    })

    return _.orderBy(servicesGrouped, ['order'], ['asc'])
  }

  const handleClickServiceType = (type) => {
    const { icon, name, color, image, id } = type

    const services = groupedServices?.filter(group => group?.name === name)[0]
    const categoryName = name?.split(' ').join('-').replace('&', 'y').toLowerCase()

    logEvent('marketplace_category', {
      user_id: uid,
      city_id: services?.cityId,
      category_id: id,
      category_name: name
    })

    // Data of the selected category is set in redux
    dispatch(setCurrentServicesByCategory({
      name,
      icon,
      color,
      image,
      services: services?.services
    }))

    if (services) {
      history.push(`/${type?.id}-${categoryName}?city_id=${services?.cityId}#${categoryName}`)
    }
  }

  const handleClickConsultService = async (service, from, callbackFunction) => {
    dispatch(setLoading(true))

    if (cityConfig) {
      const isFeatured = featured?.filter(featured => featured?.id === service.id)[0]?.id
      const isNew = news?.filter(newService => newService?.id === service?.id)[0]?.id

      logEvent('marketplace_service_cta', {
        user_id: uid,
        city_id: service?.city_id,
        group_id: service?.group_id,
        group_name: service?.group?.name,
        featured: !!isFeatured,
        recent_release: !!isNew,
        from,
        discount_percentage_value: service?.discount_percentage_value,
        price: service?.price,
        link: service?.link,
        amount_fees: service?.amount_fees,
        interest_fees_value: service?.interest_fees_value,
        service_id: service?.id
      })

      if (service?.actions?.name === 'ticket_claim' || service?.actions?.name === 'product_claim') {
        callbackFunction(true)
      } else if (service?.content?.startsWith('<p>https://') ||
        service.link.startsWith('https://') ||
        service.link.startsWith('http://') ||
        service.link.startsWith('whatsapp://') ||
        service.link.startsWith('mailto://') ||
        service.link.startsWith('tel://') ||
        service.link.startsWith('ualabee://')) {
        const url = service?.link?.replace('<p>', '')?.replace('</p>', '')
        window.open(url, '_self')
      } else {
        const cityId = cityConfig?.city_id || ''
        history.push(`/${cityId ? `?city_id=${cityId}` : ''}`)
      }

      dispatch(setLoading(false))
    }
  }

  return {
    getAll,
    groupedServices,
    handleShowMore,
    handleClickService,
    groupServicesByCategory,
    handleClickServiceType,
    handleClickConsultService
  }
}
