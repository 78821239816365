import React from 'react'
import PropTypes from 'prop-types'
import { ComponentContainer, Description, Image, Information, Name } from './styles'
import { useStyles } from './materialStyles'
import { useServices } from '../../../../hooks/useServices'
import { useSelector } from 'react-redux'

export const Result = ({ result, from }) => {
  const classes = useStyles()

  const {
    icon_url: icon,
    name
  } = result

  const { handleClickService } = useServices()

  const description = result?.type?.name + ' · ' + result?.description
  const mode = useSelector(state => state?.ui?.mode)

  return (
    <ComponentContainer
      mode={mode}
      className={classes?.result}
      onClick={() => handleClickService(result, from)}
    >
      <Image mode={mode} alt='search-result-image' src={icon} />
      <Information mode={mode}>
        <Name mode={mode}>{name}</Name>
        <Description mode={mode}>{description}</Description>
      </Information>
    </ComponentContainer>
  )
}

Result.propTypes = {
  result: PropTypes.object.isRequired
}
