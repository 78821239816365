import { firebase, ualabeeApiHost } from '../../firebase/firebase-config'

export const getCoupon = async ({ cityId, transactionId }) => {
  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  }

  return fetch(`${ualabeeApiHost}/rewards/tickets/${transactionId}?cityId=${cityId}`, reqConfig)
}
