import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './stylesMaterialConfig'
import './index.css'
import { AppRouter } from './routes/AppRouter'

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <AppRouter />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)
