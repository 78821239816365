import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'

export const TypeContainer = styled(Button)` 
    min-width: ${props => props?.mode ? 'inherit' : '146px !important'};
    min-height: ${props => props?.mode ? 'inherit' : '172px !important'};
`

export const ImageContainer = styled.div`
    margin: ${props => props?.mode ? '10px 0 0 0' : '0'};
    background-color: ${props => props?.color && props?.color}24;  
    display: flex;
    align-items: center;
    justify-content: center; 
    width: ${props => props?.mode ? '64px' : '130px'};
    height: ${props => props?.mode ? '64px' : '130px'}; 
    border-radius: 14px; 
`

export const Image = styled.img`
    width: 64px;
    height: 64px; 
`

export const Name = styled.p` 
    margin: ${props => props?.mode ? '4px 0 10px 0' : '12px 0 0 0'};
    font-size: 14px;
    color: #909397;
    text-align: center;
    line-height: 14px;
    
    white-space: ${props => props?.mode ? 'inherit' : 'nowrap'};
`
