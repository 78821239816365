import React, { useEffect, useState } from 'react'
import {
  CountriesDrawer,
  CountriesList,
  Navbar,
  Searcher,
  Title
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './materialStyles'
import { SwipeableIcon } from '../../styles'
import { ArrowBack, Search } from '@material-ui/icons'
import { SearcherContainer } from '../../components/search/Searcher/styles'
import { setCityConfig, setOpenCities, setOpenCountries } from '../../actions/ui'
import { IconButton, Snackbar } from '@material-ui/core'
import { refreshToken } from '../../actions/auth'
import ImageCard from '../ImageCard'
import { getCityByLatLng } from '../../db/getCityByLatLng'
import { setAllServices, setLoading } from '../../actions/services'
import { setLocalCityConfig } from '../../db/setLocalCityConfig'
import { getServices } from '../../db/getServices'
import { getServiceTypes } from '../../db/getServiceTypes'
import { getDistanceFromLatLonInKm } from '../../utils'
import { logEvent } from '../../firebase/firebase-config'

export const Cities = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const open = useSelector(state => state?.ui?.components?.cities)
  const cities = useSelector(state => state?.db?.cities)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.ui?.userPosition)
  const uid = useSelector(state => state?.auth?.uid)

  const [alert, setAlert] = useState()
  const [value, setValue] = useState()
  const [citySearch, setCitySearch] = useState()

  useEffect(() => {
    if (open && !citySearch) {
      setCitySearch(cities)
    }
  }, [open])

  const handleOpen = () => {
  }

  const handleClose = () => {
    if (cityConfig) {
      dispatch(setOpenCities(false))
    } else {
      dispatch(setOpenCities(false))
      setAlert(true)
      dispatch(setOpenCities(true))
    }
  }

  const handleClickCity = async (city) => {
    dispatch(refreshToken())

    const eventParams = {
      lat: userPosition || null,
      lng: userPosition || null,
      last_city_id: cityConfig ? cityConfig.city_id : -1,
      last_city_name: cityConfig ? cityConfig.name : null,
      city_id: city?.city_id,
      city_name: city?.name,
      city_distance: userPosition
        ? getDistanceFromLatLonInKm(
          userPosition?.lat,
          userPosition?.lng,
          city?.map_center_lat,
          city?.map_center_lng
        )
        : null,
      user_id: uid
    }

    logEvent('marketplace_change_city', eventParams)

    const params = {
      lat: city?.map_center_lat,
      lng: city?.map_center_lng
    }

    const currentCityConfig = await getCityByLatLng(params)

    dispatch(setCityConfig(currentCityConfig))
    setLocalCityConfig(currentCityConfig)

    const services = await getServices(currentCityConfig?.city_id)
    const serviceTypes = await getServiceTypes()

    const allServices = {
      news: services?.new_services,
      featured: services?.featured_services,
      services: services?.services,
      types: serviceTypes
    }

    dispatch(setAllServices(allServices))
    dispatch(setCityConfig(currentCityConfig))
    dispatch(setLoading(false))
  }

  const handleBack = () => {
    dispatch(setOpenCities(false))
    dispatch(setOpenCountries(true))
  }

  const handleChange = (e) => {
    setValue(e.target.value)

    const results = cities?.filter((city) =>
      city.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())
    )

    setCitySearch(results)
  }

  return (
    <CountriesDrawer
      disableDiscovery
      disableSwipeToOpen
      classes={{
        paper: classes.paper,
        modal: classes.modal
      }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      anchor='bottom'
    >
      <Navbar>
        <SwipeableIcon />
        <IconButton
          onClick={handleBack}
          classes={{ root: classes.backButton }}
          aria-label='search'
        >
          <ArrowBack classes={{ root: classes.backIcon }} />
        </IconButton>
        <Title>Elige tu ciudad</Title>
        <SearcherContainer>
          <Searcher
            placeholder='¿Qué ciudad buscas?'
            classes={{ root: classes.searcher }}
            value={value}
            onChange={handleChange}
          />
          <Search classes={{ root: classes.search }} />
        </SearcherContainer>
      </Navbar>
      <CountriesList>
        {citySearch?.map((city) => {
          return (
            <ImageCard
              onClickFunction={() => handleClickCity(city)}
              item={city}
              key={city?.city_id}
              backgroundImg={city?.landscape_picture}
              title={city?.name}
            />
          )
        })}
      </CountriesList>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alert}
        onClose={() => setAlert(false)}
        message='Debes seleccionar una ciudad para continuar.'
      />
    </CountriesDrawer>
  )
}
