import React, { useEffect } from 'react'
import { Searcher } from '../Searcher'
import {
  SearchContainer
} from './styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSearch } from '../../../hooks/useSearch'
import { HelperMessage } from '../../../uiComponents/search/results/HelpertMessage'
import { Result } from '../../../uiComponents/search/results/Result'

export const Search = () => {
  const history = useHistory()

  const {
    results
  } = useSearch()

  const { value } = useSelector(state => state?.services?.search)
  const mode = useSelector(state => state?.ui?.mode)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    if (!mode) {
      if (cityConfig) {
        history.push(`/?city_id=${cityConfig.city_id}`)
      } else {
        history.push('/')
      }
    }
  }, [])

  return (
    <SearchContainer>
      <Searcher
        autoFocus
      />

      {value?.length > 0
        ? (
          <div>
            {results?.map((result) => {
              return (
                <Result key={result?.id} result={result} />
              )
            })}
          </div>
          )
        : (
          <HelperMessage />
          )}
    </SearchContainer>
  )
}
