import styled from 'styled-components/macro'

export const Container = styled.div`  
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: 16px;
`

export const Icon = styled.div`   
`

export const Text = styled.p`   
    margin: 0;
`
