import React from 'react'
import { useSelector } from 'react-redux'
import { Header } from '../header/Header'
import { Types } from '../services/types/Types'
import { SkeletonDesktop } from '../Skeleton'
import { MarketplaceContainer } from './styles'
import { Featured } from '../services/featured/Featured'
import { News } from '../services/news/News'
import { Services } from '../services/services/Services'
import { Helmet } from 'react-helmet'
import squareIcon from '../../../../img/square_icon.png'
import { Detail } from '../services/Detail'
import { Countries } from '../../../../uiComponents/Countries'

export const Marketplace = () => {
  const loading = useSelector(state => state?.services?.loading)

  return (
    <>
      {loading
        ? (
          <MarketplaceContainer>
            <SkeletonDesktop />
            <Countries />
          </MarketplaceContainer>
          )
        : (
          <MarketplaceContainer>
            <Header />
            <Types />
            <Featured />
            <News />
            <Services />
            <Detail />
          </MarketplaceContainer>
          )}

      <Helmet>
        <title>Tienda Ualabee</title>
        <link href={squareIcon} rel='icon' />
        <link href={squareIcon} rel='apple-touch-icon' />
        <meta content='Tienda Ualabee' property='og:title' />
        <meta
          content='Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología, tienda ualabee, tienda ualabe, tienda gualabe, tienda walabe, tienda ualabi, tienda ualaby, tienda walabi, tienda wallabi, tienda wallaby, tienda wallabee, tienda walaby, tienda walabee, tienda gualabi, tienda gualaby, tienda gualabee, tienda guallabi, tienda guallabee, tienda ualavee, tienda ualave, tienda gualave, tienda walave, tienda ualavi, tienda ualavy, tienda walavi, tienda wallavi, tienda wallavy, tienda wallavee, tienda walavy, tienda walavee, tienda gualavi, tienda gualavy, tienda gualavee, tienda guallavi, tienda guallavee'
          name='keywords'
        />

        <meta content='website' property='og:type' />
        <meta content='Tienda Ualabee' property='og:site_name' />
        <meta content='es_ES' property='og:locale' />
        <meta content='en_GB' property='og:locale:alternate' />
        <meta content='en_US' property='og:locale:alternate' />
        <meta
          content='Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología, tienda ualabee, tienda ualabe, tienda gualabe, tienda walabe, tienda ualabi, tienda ualaby, tienda walabi, tienda wallabi, tienda wallaby, tienda wallabee, tienda walaby, tienda walabee, tienda gualabi, tienda gualaby, tienda gualabee, tienda guallabi, tienda guallabee, tienda ualavee, tienda ualave, tienda gualave, tienda walave, tienda ualavi, tienda ualavy, tienda walavi, tienda wallavi, tienda wallavy, tienda wallavee, tienda walavy, tienda walavee, tienda gualavi, tienda gualavy, tienda gualavee, tienda guallavi, tienda guallavee'
          name='keywords'
        />
      </Helmet>
    </>
  )
}
