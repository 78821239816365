import { firebase, ualabeeApiHost } from '../../firebase/firebase-config'

export const getRechargeServices = async () => {
  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  }

  return fetch(`${ualabeeApiHost}/payments/getRechargeServices`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .then((response) => response?.result)
    .catch((err) => console.log(err))
}
