import { types } from '../types/types'

export const dbReducer = (state = {

}, action) => {
  switch (action.type) {
    case types.countries_list:
      return {
        ...state,
        countries: action.payload
      }
    case types.cities_list:
      return {
        ...state,
        cities: action.payload
      }
    default:
      return state
  }
}
