import styled from 'styled-components/macro'

export const Container = styled.div`
    background-image: url(${props => props?.src});
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat; 
    width: 80%;
    height: 170px; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const CardContainer = styled.div` 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
`

export const Image = styled.img`
    max-width: ${props => props.extend ? 'auto' : '58px'};
`

export const Name = styled.p`
    margin: 4px 0 0 0;   
    color: ${props => props.color ? props.color : '#3D3E3F'};
`

export const Number = styled.p`
    margin: 0;   
    color: ${props => props.color ? props.color : '#3D3E3F'};
`

export const Data = styled.div` 
    margin-left: 30px; 
`
