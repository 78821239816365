import { firebase, ualabeeApiHost } from '../../firebase/firebase-config'
import PropTypes from 'prop-types'

export const deleteUserRechargeCard = async (id) => {
  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(id)
  }

  return fetch(`${ualabeeApiHost}/payments/userRechargeCards`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .catch((err) => console.log(err))
}

deleteUserRechargeCard.propTypes = {
  token: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}
