import styled from 'styled-components/macro'

export const CardContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-height: 109px;
  border-radius: 10px;
  padding: 14px 10px;
  margin-bottom: 12px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-box-shadow: inset 0px -39px 22px -10px rgba(0,0,0,0.65);
-moz-box-shadow: inset 0px -39px 22px -10px rgba(0,0,0,0.65);
box-shadow: inset 0px -39px 22px -10px rgba(0,0,0,0.65);
  background-image: ${(props) => {
    return props.backgroundImg && `url(${props.backgroundImg})`
}};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  :last-child {
    margin-bottom: 0px;
  }
`

export const CardTitle = styled.h3`
  font-family: Visby;
  color: #ffffff;
  font-size: 15px;
  margin: 0;
  padding: 0;
`
