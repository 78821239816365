import React, { useEffect } from 'react'
import {
  Card,
  CardContainer,
  Container,
  Data,
  DeleteButton,
  DeleteIcon,
  EditIcon,
  Image,
  Info,
  Name,
  Number,
  RenameButton,
  UserCards
} from './styles'
import { Navbar } from '../../../../uiComponents/movypay/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './material'
import { Secondary as AddAnotherCard } from '../../../../uiComponents/movypay/buttons/Secondary'
import { setCurrentCard, setOpenDeleteDialog, setOpenEditDialog } from '../../../../actions/recharge'
import { DeleteCard } from '../../../../uiComponents/movypay/cards/DeleteCard'
import { useHistory } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'
import { EditCard } from '../../../../uiComponents/movypay/cards/EditCard'
import { LoadingContainer } from '../Amount/styles'
import { CircularProgress } from '@material-ui/core'
import useQuery from '../../../../hooks/useQuery'

export const Cards = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const query = useQuery()

  const company = useSelector(state => state?.recharge?.company)
  const cards = useSelector(state => state?.recharge?.user_cards)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const uid = useSelector(state => state?.auth?.uid)

  useEffect(() => {
    if (cards?.length === 0 && company) {
      history.push(`/card-recharge/card-data?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
    }
  }, [])

  const handleClickCurrentCard = (card) => {
    logEvent('recharge_card_select', {
      card_name: card?.name, // String
      card_number: card?.card_ref_id, // String
      card_id: card?.id, // Int
      card_company_name: card?.recharge_services?.name, // String
      user_id: uid, // String
      city_id: cityConfig?.city_id // Int
    })

    dispatch(setCurrentCard(card))
    history.push(`/card-recharge/amount-selection?recharge_service_id=${company?.id}&card_id=${card?.id}&custom_token=${query.get('custom_token') || null}`)
  }

  const handleAddCard = () => {
    history.push(`/card-recharge/card-data?recharge_service_id=${company?.id}&custom_token=${query.get('custom_token') || null}`)
  }

  const handleRename = (card) => {
    dispatch(setCurrentCard(card))
    dispatch(setOpenEditDialog(true))
  }

  const handleDelete = (card) => {
    dispatch(setCurrentCard(card))
    dispatch(setOpenDeleteDialog(true))
  }

  return (
    <Container>

      {company
        ? (
          <>

            <Navbar label={`Elegir tarjeta ${company?.name}`} />
            <UserCards>
              {cards?.map((card) => {
                return (
                  <CardContainer key={card?.id}>
                    <Card
                      onClick={() => handleClickCurrentCard(card)}
                      className={classes.card}
                    >
                      <Info>
                        <Image src={company?.icon_url} />
                        <Data>
                          <Name>{card?.name}</Name>
                          <Number>{card?.card_ref_id}</Number>
                        </Data>
                      </Info>
                    </Card>
                    <RenameButton
                      className={classes.delete}
                      onClick={() => handleRename(card)}
                    >
                      <EditIcon fontSize='small' />
                    </RenameButton>
                    <DeleteButton
                      className={classes.delete}
                      onClick={() => handleDelete(card)}
                    >
                      <DeleteIcon fontSize='small' />
                    </DeleteButton>
                  </CardContainer>
                )
              })}

            </UserCards>
            <AddAnotherCard
              bottom={0}
              label={`Agregar otra ${company?.name}`}
              onClickFunction={handleAddCard}
              styles={{ position: 'relative', marginTop: 32 }}
            />
            <DeleteCard />
            <EditCard />
          </>
          )
        : (
          <LoadingContainer>
            <CircularProgress color='inherit' />
          </LoadingContainer>
          )}

    </Container>
  )
}
