import { firebase, ualabeeApiHost } from '../../firebase/firebase-config'

export const claimCoupon = async ({ cityId, agencyId, marketplaceServiceId }) => {
  const token = await firebase.auth().currentUser.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ cityId, agencyId, marketplaceServiceId })
  }

  return fetch(`${ualabeeApiHost}/rewards/tickets`, reqConfig)
}
