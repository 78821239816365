import styled from 'styled-components/macro'

export const ComponentContainer = styled.div` 
`

export const DetailsContainer = styled.div`
    background-color: grey;
`

export const DialogContentContainer = styled.div` 
     display: flex;
     align-items: flex-start;
     width: 100%;
`

export const Header = styled.div` 
    height: calc(24vh + 14px);
    position: relative;
`

export const ContentContainer = styled.div`
    border-top-right-radius: 14px; 
    border-top-left-radius: 14px;  
    position: absolute;
    top: 24vh; 
    width: 100vw;
    background-color: white;    
    overflow: scroll;
    z-index: 1;
`

export const BrandContainer = styled.div` 
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    margin-top: ${props => props?.mode ? '20px' : '0'};
`

export const Content = styled.div`  
    padding: ${props => props?.mode ? '14px 16px' : ' 0 16px 0 16px'}; 
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    z-index: 2;    
    margin: ${props => props?.mode ? '0 0 70px 0' : 0};
    max-width: ${props => props?.mode ? 'inherit' : '400px'};
`

export const BrandLogo = styled.div` 
    width: 35px;
    height: 35px; 
    background-image: url(${props => props?.src}); 
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-right: 12px; 
`

export const BrandName = styled.p` 
    margin: 0;
`

export const Name = styled.h1`   
    font-size: 18px;
    margin-bottom: ${props => props?.mode ? 'inherit' : '6px'};
`

export const Description = styled.div`  
`

export const OldPrice = styled.p` 
    margin: 0px 0px 0px 12px;
    font-size: 14px;
    text-decoration: line-through;
    color: #909397; 
    height: 22px;
    line-height: normal;
`

export const Price = styled.h3` 
    margin: 0px;
    font-size: 24px;
    color: #222831;
`

export const Picture = styled.div`
    width: ${props => props?.width ? props.width : '100%'};
    height: ${props => props?.height ? props.height : '100%'}; 
    border-radius: ${props => props?.borderRadius ? props.borderRadius : 'none'}; 
    
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`

export const Discount = styled.h4`  
    margin: 0 12px 0px 0px;
    font-size: 16px; 
    line-height: normal;
    height: 22px;
`

export const DiscountDetails = styled.p`  
    margin: 0px; 
    font-size: 16px; 
    color:${props => props?.color ? '#198f4c' : 'black'};
    line-height: normal;
`

export const Title = styled.p`  
    margin: 24px 0 0 0; 
    font-size: 18px;  
`

export const ContentDescriptionContainer = styled.div`   
`

export const ContentDescription = styled.p`  
    margin: ${props => props?.mode ? '6px 0 74px 0' : '6px 0 0 0'}; 
    font-size: 16px;  
    max-height: ${props => props?.mode ? 'none' : '115px'};
    overflow: ${props => props?.mode ? 'none' : 'auto'}; 
    overflow-x: hidden;
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 20px;
    }
`

export const ButtonContainer = styled.div`     
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 20px;
`

export const ContentButtonContainer = styled.div`     
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ServicePrice = styled.div`   
    display: flex;
    align-items: center;
    color: #198F4C;
    margin-bottom: 5;      
`

export const ButtonText = styled.p`    
    margin: 0;
`

export const PictureContainer = styled.div`    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props?.mode ? '50px' : '20px'};
    padding-top: ${props => props?.mode ? '14px' : '0'};   
`
