import { useLocation } from 'react-router-dom'

/**
 * useQuery()
 * @returns {URLSearchParams}
 */

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default useQuery
