import styled from 'styled-components/macro'
import { Button, InputBase } from '@material-ui/core'
import { ArrowBackRounded, SearchRounded } from '@material-ui/icons'

export const SearcherContainer = styled.div`   
    position: relative !important;  
    width: 100%;
        display: flex;
    align-items: center;
`

export const SearcherInput = styled(InputBase)`
    width: 100%;
`

export const SearcherIcon = styled(SearchRounded)`   
    color: #A6A9AC;
    margin: 0 12px;
`
export const BackIcon = styled(ArrowBackRounded)`   
    color: #A6A9AC;
    margin: 0 12px;
`

export const SearchButton = styled(Button)`    
`

export const Results = styled.div`   
    width: 448px;
    border: 1px solid #E0E8F7;
    display: flex;
    padding: 10px 0 10px 0;
    z-index: 1;
    position: absolute;
    box-shadow: rgb(34 40 49 / 8%) 0px 1px 6px;
    max-height: 322px;
    border-radius: 0;
    flex-direction: column;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;  
    overflow: auto; 
    
    overflow-x: hidden; 
    ::-webkit-scrollbar { 
      width: 3px;
      height: 3px; 
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
        margin-top: 5px;
      border-radius: 7px;
      background-color: #E0E8F7; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 20px;
    }
`

export const SearchResults = styled.div``
