export const setUserCards = (data) => {
  localStorage.setItem('cards', JSON.stringify(data))
}

export const setLocalTransaction = (data) => {
  localStorage.setItem('transaction_id', JSON.stringify(data))
}

export const getLocalTransaction = async () => {
  return await JSON.parse(localStorage.getItem('transaction_id'))
}
