import styled from 'styled-components/macro'
import { Button, IconButton } from '@material-ui/core'
import { Close, EditRounded } from '@material-ui/icons'

const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

export const Container = styled.div`    
    height: ${height}px; 
    max-height: 100vh; 
    overflow: hidden;
    background-color: white;
`

export const UserCards = styled.div`
    padding: 24px 12px;      
    background-color: white; 
    border-radius: 16px;  
    margin: 24px 0 0 0; 
    position: relative;
    max-height: 68%;
    overflow: auto;
`

export const Card = styled(Button)`      
`

export const Image = styled.div`   
    background-image: url(${props => props?.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 44px;
    height: 44px;
    border-radius: 25px;
    margin-bottom: 8px; 
    margin: 0 12px 0 0;
`

export const Info = styled.div`  
    display: flex;
    align-items: center;
    justify-content: flex-start;    
`

export const Data = styled.div`    
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;    
`

export const Name = styled.p`   
    margin: 0 0 5px 0;  
          max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis; 
`

export const Number = styled.p`   
    margin: 0; 
          max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis; 
`

export const DeleteButton = styled(IconButton)`  
    z-index: 600 !important; 
`

export const RenameButton = styled(IconButton)`  
    z-index: 600 !important; 
`

export const DeleteIcon = styled(Close)`   
`

export const EditIcon = styled(EditRounded)`   
`

export const CardContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & last-child {
        margin-bottom: 0,
    },
`
