import React, { useEffect, useRef, useState } from 'react'
import {
  CategoryContent,
  CategoryHeader,
  ContentContainer,
  FeaturedContainer,
  Group,
  GroupName,
  HeaderContainer,
  Icon,
  Name,
  Opacity,
  ServiceByCategoryContainer,
  ServicesContainer,
  ServicesGroupedContainer
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../styles'
import { useHistory, useLocation } from 'react-router-dom'
import { FeaturedServices } from '../../featured/FeaturedServices'
import { setForceRedirect, setLoading } from '../../../../actions/services'
import _ from 'lodash'
import { Service } from '../../notFeatured/Service'
import { useServices } from '../../../../hooks/useServices'
import { CircularProgress } from '@material-ui/core'
import { setCurrentServicesByCategory } from '../../../../actions/ui'
import useQuery from '../../../../hooks/useQuery'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Helmet } from 'react-helmet'
import squareIcon from '../../../../img/square_icon.png'
import { Marketplace } from '../../../desktop/marketplace/Marketplace'

export const ByCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const query = useQuery()
  const serviceRef = useRef(null)

  const {
    groupedServices,
    groupServicesByCategory
  } = useServices()

  const category = useSelector(state => state?.ui?.current?.services?.category)
  const mode = useSelector(state => state?.ui?.mode)
  const services = useSelector(state => state?.services?.services)
  const loading = useSelector(state => state?.services?.loading)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const forceRedirect = useSelector(state => state.services.forceRedirect)
  const cityId = Number(query.get('city_id'))
  const groupId = Number(pathname?.split('-')[0]?.replace('/', ''))

  const [servicesGrouped, setServicesGrouped] = useState()

  useEffect(() => {
    if (!mode) {
      setTimeout(() => {
        const button = document.getElementById('category-' + pathname?.split('/')[1]?.substring(2))
        button?.click()
      }, 3000)
    }
  }, [])

  useEffect(() => {
    if (mode) {
      const services = _.groupBy(category?.services, 'group_id')

      const groupedServices = Object?.keys(services)?.map((key) => {
        return {
          image: services[key][0]?.group?.logo_url,
          name: services[key][0]?.group?.name,
          id: services[key][0]?.group?.id,
          services: services[key]
        }
      })

      setServicesGrouped(groupedServices)
    }
  }, [category])

  useEffect(() => {
    if (!loading && mode) {
      const servicesGrouped = groupServicesByCategory(services?.services)

      const currentCategory = servicesGrouped.filter(group => group?.groupId === groupId && group?.cityId === cityId)[0]

      if (!currentCategory) {
        if (cityConfig) {
          history.push(`/?city_id=${cityConfig.city_id}`)
        } else {
          history.push('/')
        }
      } else {
        //  Featured services that match the id of the service types in the selected category are searched for.
        const featuredServices = services?.featured?.filter((service) => {
          return service?.type_id === currentCategory?.services[0]?.type_id
        })

        const servicesByCategory = {
          name: currentCategory?.name,
          icon: currentCategory?.icon,
          color: currentCategory?.color,
          services: currentCategory?.services,
          featured: featuredServices,
          image: currentCategory?.image
        }

        // Data of the selected category is set in redux

        dispatch(setCurrentServicesByCategory({ ...servicesByCategory, featured: featuredServices }))
        dispatch(setLoading(false))
      }
    }
  }, [loading])

  useEffect(() => {
    if (serviceRef?.current && query.get('custom_token') && forceRedirect) {
      serviceRef?.current?.click()
      dispatch(setForceRedirect(false))
    }
  }, [servicesGrouped])

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp({
            '@context': 'https://schema.org/',
            '@type': 'ProductGroup',
            productGroupID: groupedServices?.id,
            hasVariant: category?.name?.replace('&', 'y'),
            variesBy: [
              category?.image,
              category?.name,
              category?.id,
              category?.services
            ],
            offers: 'Offer'
          })
        ]}
      >
        <title>{category?.name ? `Tienda Ualabee - ${category?.name?.replace('&', 'y')}` : 'Tienda'}</title>
        <meta
          name='description'
          content='Tienda online de productos y servicios que resuelven tu movilidad. Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología.'
        />
        <link href={squareIcon} rel='icon' />
        <link href={squareIcon} rel='apple-touch-icon' />
        <meta content={`Tienda Ualabee - ${category?.name}`} property='og:title' />
        <meta
          property='og:description'
          content='Tienda online de productos y servicios que resuelven tu movilidad. Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología.'
        />
        <meta content='website' property='og:type' />
        <meta content='Tienda Ualabee' property='og:site_name' />
        <meta content='es_ES' property='og:locale' />
        <meta content='en_GB' property='og:locale:alternate' />
        <meta content='en_US' property='og:locale:alternate' />
        <meta
          content='Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología, tienda ualabee, tienda ualabe, tienda gualabe, tienda walabe, tienda ualabi, tienda ualaby, tienda walabi, tienda wallabi, tienda wallaby, tienda wallabee, tienda walaby, tienda walabee, tienda gualabi, tienda gualaby, tienda gualabee, tienda guallabi, tienda guallabee, tienda ualavee, tienda ualave, tienda gualave, tienda walave, tienda ualavi, tienda ualavy, tienda walavi, tienda wallavi, tienda wallavy, tienda wallavee, tienda walavy, tienda walavee, tienda gualavi, tienda gualavy, tienda gualavee, tienda guallavi, tienda guallavee'
          name='keywords'
        />
      </Helmet>
      {loading
        ? (<Loading><CircularProgress style={{ color: '#197d8f' }} /></Loading>)
        : (<>
          {mode
            ? (<ServiceByCategoryContainer>
              <CategoryHeader src={category?.image}>
                <Opacity />
                <HeaderContainer>
                  <Name>{category?.name}</Name>
                </HeaderContainer>
              </CategoryHeader>
              <CategoryContent>
                <ContentContainer>
                  {category?.featured &&
                    <FeaturedContainer featured={category?.featured > 1}>
                      <FeaturedServices />
                    </FeaturedContainer>}
                  <ServicesGroupedContainer>
                    {servicesGrouped?.map((group, i) => {
                      return (
                        <div key={i}>
                          <Group
                            mode={mode}
                            isNew={group?.services[0]?.new_service}
                          >
                            {group?.image && <Icon src={group?.image} />}
                            <GroupName>{group.name}</GroupName>
                          </Group>
                          <ServicesContainer
                            fistItemIsNew={group?.services[0]?.new_service}
                          >
                            {group?.services?.map((service, i) => {
                              return (
                                <Service
                                  serviceRef={serviceRef}
                                  key={i}
                                  name={service?.name}
                                  description={service?.description}
                                  icon={service?.icon_url}
                                  billingPeriod={service?.billing_period}
                                  groupName={service?.group?.name}
                                  price={service?.price}
                                  service={service}
                                />
                              )
                            })}

                          </ServicesContainer>
                        </div>
                      )
                    })}
                  </ServicesGroupedContainer>
                </ContentContainer>
              </CategoryContent>
            </ServiceByCategoryContainer>
              )
            : (
              <Marketplace />
              )}
        </>)}
    </>

  )
}
