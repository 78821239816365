import { firebase } from '../firebase/firebase-config'
import { types } from '../types/types'
import { setAppConfiguration } from './ui'

/**
 * setUserConfig() - redux action
 * @param uid
 * @param token
 * @param user
 * @returns {{payload: {uid, user, token}, type: string}}
 */

export const setUserConfig = (uid, token, user) => ({
  type: types.user,
  payload: {
    uid,
    token,
    user
  }
})

/**
 * startLogin()
 * @returns {(function(*): void)|*} - Sets user params in redux store
 */

export const startLogin = () => {
  return async (dispatch) => {
    const query = new URLSearchParams(window.location.search)

    localStorage.removeItem('transaction_id')
    localStorage.removeItem('custom_token')

    if (query.get('custom_token') && query.get('custom_token') !== 'null') {
      await firebase.auth().signInWithCustomToken(query.get('custom_token'))
    }

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken(false).then(function (idToken) {
          dispatch(setUserConfig(user.uid, idToken, user))
          sessionStorage.setItem('token', idToken)
          sessionStorage.setItem('uid', user?.uid)
        })

        dispatch(setAppConfiguration())
      } else {
        await firebase.auth().signInAnonymously()
      }
    })
  }
}

export const setCityId = (cityId) => ({
  type: types.cityId,
  payload: cityId
})

export const refreshToken = () => {
  return async (dispatch, getState) => {
    const { auth } = getState()

    const currentToken = await auth?.user?.getIdToken()

    dispatch(setUserConfig(
      auth?.uid,
      currentToken,
      auth?.user
    ))
  }
}
