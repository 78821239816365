import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'
import { ErrorRounded } from '@material-ui/icons'

const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

export const Container = styled.div`    
    height: ${height}px; 
    max-height: ${height}px; 
    overflow: hidden;
    position: relative;  
`

export const Card = styled.div`   
    padding: 49px 12px 20px 12px; 
`

export const Information = styled.div`   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 98px;
`

export const Image = styled.div`   
    background-image: url(${props => props?.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 52px;
    height: 52px;
    border-radius: 25px;
    margin-bottom: 8px;
`

export const Name = styled.p`
    margin: 0 0 4px 0;    
    color: #555A60;
    font-size: 14px;
`

export const CompanyName = styled.p`
    margin: 0 0 4px 0;    
    color: #555A60;
    font-size: 14px;
    font-weight: 600;
`

export const Number = styled.p`
    margin: 0;    
    color: #555A60;
    font-size: 14px;
`

export const Title = styled.h1`
    margin: 0; 
    font-size: 14px; 
    color: #3D3E3F;
    text-align: center;
    margin-top: 56px;
`

export const Amounts = styled.div` 
    margin: 20px 0 0 0;
`

export const Price = styled(Button)`  
`

export const LoadingContainer = styled.div` 
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; 
    min-height: 150px;
    color: #e3e3e3;
    height: 100vh;
`

export const Content = styled.div` 
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px 36px;
    width: 70%;
    text-align: center;
`

export const ErrorIcon = styled(ErrorRounded)` 
    color: #F46262;
    width: 40px;
    height: 40px;
`

export const ErrorMessage = styled.p` 
`
