import React from 'react'
import { BackButton, BackIcon, Container, Title } from './styles'
import PropTypes from 'prop-types'
import { useStyles } from './material'
import { useHistory } from 'react-router-dom'

export const Navbar = ({ label }) => {
  const history = useHistory()

  const classes = useStyles()

  const handleClick = () => {
    history.goBack()
  }

  return (
    <Container>
      <BackButton
        className={classes.back}
        onClick={handleClick}
      >
        <BackIcon />
      </BackButton>
      <Title>{label}</Title>
    </Container>
  )
}

Navbar.propTypes = {
  label: PropTypes.string.isRequired
}
