import React from 'react'
import {
  BusInfo,
  Container,
  ContentContainer,
  Header,
  Information,
  Item,
  Items,
  Logo,
  SubTitle,
  Text,
  Title,
  Content,
  Network,
  CheckIcon,
  Footer, Info
} from './styles'
import logo from '../../../img/ualabeelogo.svg'
import { useStyles } from '../../../components/mobile/movypay/Download/material'
import { PoweredBy } from '../PoweredBy'

export const Payment = ({ items }) => {
  const classes = useStyles()

  return (
    <Container id='page'>
      <Header>
        <Logo src={logo} alt='movypay logo' />
        <Title>Comprobante de pago</Title>
      </Header>
      <Information>
        <SubTitle>Detalle de la operación</SubTitle>
        <Items>
          {items?.map((item) => {
            return (
              <Item key={item?.name}>
                <Text>  {item?.name} </Text>
                <ContentContainer>
                  {item?.src || item.checked
                    ? (
                      <BusInfo>
                        {item.src
                          ? (
                            <>
                              <Content color={item?.color}>{item?.content}</Content>
                              <Network src={item?.src} />
                            </>
                            )
                          : (
                            <>
                              <Content color={item?.color}>{item?.content}</Content>
                              <CheckIcon className={classes.checkSmall} fontSize='small' />
                            </>
                            )}
                      </BusInfo>
                      )
                    : (
                      <Content color={item?.color}>{item?.content}</Content>
                      )}
                </ContentContainer>
              </Item>
            )
          })}
        </Items>
        <Footer>
          <Info>
            En su resumen de cuenta identificará el consumo como Movypay
          </Info>
          <PoweredBy />
        </Footer>
      </Information>
    </Container>
  )
}
