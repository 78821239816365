import styled from 'styled-components/macro'

export const SearchContainer = styled.div`  
    padding: 0 16px;
`

export const Image = styled.div`    
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    border-radius: 8px;
    margin-right: 16px;
`

export const Name = styled.p`     
    margin: 0; 
    font-size: 18px;
    font-weight: 600;

    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
