import React from 'react'
import { ComponentContainer } from './styles'
import { CircularProgress } from '@material-ui/core'
import { Logo } from '../header/Header/styles'
import ualabeeLogo from '../../../../img/ualabeelogo.svg'

export const SkeletonDesktop = () => {
  return (
    <ComponentContainer>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      >
        <h1 style={{ margin: '0 15px 0 0', color: '#197d8f' }}>Bienvenid@ a </h1>
        <Logo style={{ width: 200, height: 70 }} src={ualabeeLogo} />
      </div>
      <h2 style={{ margin: '0', color: '#197d8f' }}>Tienda</h2>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        flexDirection: 'column',
        justifyContent: 'center'
      }}
      >
        <div>
          <CircularProgress style={{ marginRight: 16, color: '#197d8f', width: 30, height: 30 }} />
        </div>
        <p>Estamos buscando las mejores ofertas para vos... </p>
      </div>

    </ComponentContainer>
  )
}
